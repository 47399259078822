<template>
    <v-layout wrap>
        <v-flex class="d-flex justify-space-between mx-5" xs12>
            <span class="display-1 pb-5 font-weight-medium">{{
                $route.meta.name
            }}</span>
            <v-btn @click="back" color="secondary">Înapoi</v-btn>
        </v-flex>
        <v-flex class="mx-5" xs12>
            <v-card class="pa-5">
                <v-layout class="flex-wrap flex-lg-nowrap">
                    <v-flex lg6 space-between xs12>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.name"
                                @input="delete errors.name"
                                label="Nume"
                                v-model="data.name"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.phone"
                                @input="delete errors.phone"
                                label="Telefon"
                                v-model="data.phone"
                            ></v-text-field>
                        </v-flex>

                        <v-flex>
                            <v-text-field
                                :error-messages="errors.email"
                                @input="delete errors.email"
                                label="Email"
                                v-model="data.email"
                            ></v-text-field>
                        </v-flex>
                        <v-flex>
                            <v-text-field
                                :error-messages="errors.party"
                                @input="delete errors.party"
                                label="Partid"
                                v-model="data.party"
                            ></v-text-field>
                        </v-flex>
                    </v-flex>
                    <v-divider class="mx-3" vertical></v-divider>
                    <v-flex lg6 xs12>
                        <v-flex>
                            <v-file-input
                                :error-messages="errors.image"
                                @change="loadImage"
                                accept="image/*"
                                label="Poză consilier"
                                v-model="data.image"
                            ></v-file-input>
                        </v-flex>
                        <v-flex class="text-center">
                            <img
                                :src="imageSrc"
                                height="300px"
                                id="preview"
                                style="border-radius: 100%"
                                width="300px"
                            />
                        </v-flex>
                    </v-flex>
                </v-layout>
                <v-card-actions class="my-10 flex-column flex-md-row">
                    <v-spacer class="hidden-md-and-down"></v-spacer>
                    <v-btn @click="saveAndBack" color="secondary" width="300px"
                        >Salvează</v-btn
                    >
                    <v-btn
                        class="mt-2 mt-md-0"
                        v-if="!editMode"
                        @click="save"
                        color="secondary"
                        width="300px"
                        >Salvează și rămăi pe pagină</v-btn
                    >
                    <v-spacer class="hidden-md-and-down"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import displayErrors from "@/mixins/displayErrors";
import back from "@/mixins/back";
import editMode from "@/mixins/editMode";

export default {
    name: "AddCounselor",
    metaInfo: {
        title: "Consiliul Local -"
    },
    data: () => ({
        resourceUri: "/councilors"
    }),
    mixins: [displayErrors, back, editMode],
    computed: {
        imageSrc() {
            return this.data.src
                ? this.data.src
                : `${require("@/assets/person_placeholder.png")}`;
        }
    },
    methods: {
        loadImage() {
            delete this.errors.image;
            const file = this.data.image;
            const outImage = "preview";
            if (FileReader && file) {
                const fr = new FileReader();
                fr.onload = function() {
                    document.getElementById(outImage).src = fr.result;
                };
                fr.readAsDataURL(file);
            }
        }
    }
};
</script>
